import qs from 'qs'

export const queryMenusMenu = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      filters: {
        slug: {
          $eq: 'menu-v2'
        }
      },
      nested: true,
      populate: {
        items: {
          populate: {
            clusters_page: {
              populate: ['titles']
            },
            events_page: {
              populate: ['titles']
            },
            home_page: {
              populate: ['titles']
            },
            news_page: {
              populate: ['titles']
            },
            map_page: {
              populate: ['titles']
            },
            participants_page: {
              populate: ['titles']
            },
            partners_page: {
              populate: ['titles']
            },
            publications_page: {
              populate: ['titles']
            },
            press_page: {
              populate: ['titles']
            },
            themes_page: {
              populate: ['titles']
            },
            cities_page: {
              populate: ['titles']
            },
            venues_page: {
              populate: ['titles']
            },
            focus_week_page: {
              populate: ['titles']
            },
            page_entry: {
              populate: ['titles', 'slugs']
            }
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `menus?${query}`
}
