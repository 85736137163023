<template>
  <div
    :class="$style.root"
    ref="rootElement"
  >
    <ul
      :class="$style.list"
      v-if="items?.length > 0"
    >
      <li
        v-for="(item, index) in items"
        :key="`item-${index}`"
        :class="$style.item"
      >
        <NavigationMenuItemComponent
          :data="item"
          @setActiveGroup="activeGroup = $event"
          :activeGroup="activeGroup"
          :index="index"
        />
      </li>
    </ul>

    <div
      :class="$style.loading"
      v-if="pending"
    >
      Loading …
    </div>

    <div
      id="menu-submenu"
      :class="$style.subMenu"
      ref="subMenuElement"
    />
  </div>
</template>

<script setup>
const router = useRouter()
const { isDesktop } = useDevice();

// 1
const { data, execute, pending } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryMenusMenu() } })
}, { immediate: false })

onMounted(() => {
  execute()
})

const page = computed(() => baseStrapiGetAttributes(data.value))
const items = computed(() => {
  return navigationGetMenuItems(page.value?.items?.data)
})

// 2
const whenHasFirstGroupThenSetActiveGroup = computed(() => {
  const pageIsHomepage = router.currentRoute.value.name?.split('__')?.[0] === 'index'
  if (items.value?.length > 0 && pageIsHomepage && isDesktop) {
    return items.value?.find((item) => item?.type === 'group')?.title || null
  } else {
    return null
  }
})

const activeGroup = ref(whenHasFirstGroupThenSetActiveGroup.value)

// 3
const rootElement = ref(null)
const subMenuElement = ref(null)

useMutationObserver(subMenuElement, (mutations) => {
  for (const mutation of mutations) {
    if ( mutation?.target?.childNodes?.length > 0) {
      rootElement.value.setAttribute('data-has-submenu', true)
    } else {
      rootElement.value.removeAttribute('data-has-submenu')
    }
  }
}, {
  childList: true,
  attributes: false,
  subtree: false
})
</script>

<style module lang="scss">
.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: calc(var(--unit--horizontal) * 2);

  @media (max-width: $breakpoint-s) {
    display: grid;
    grid-template-columns: 1fr;

    &[data-has-submenu] .list {
      display: none;
    }
  }
}

.list {
  composes: reset-list font-size-large from global;
  line-height: var(--line-height--large);
  text-align: right;

  @media (max-width: $breakpoint-m) {
    font-size: var(--font-medium--font-size);
    letter-spacing: var(--font-medium--letter-spacing);

    & * {
      text-underline-offset: var(--font-medium--text-underline-offset);
      text-decoration-thickness: var(--font-medium--text-decoration-thickness);
    }
  }

  @media (max-width: $breakpoint-s) {
    text-align: left;
  }
}

.subMenu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.loading {
  composes: font-size-medium from global;
  text-align: center;
  justify-content: center;
  grid-column: 1 / -1;
}
</style>
